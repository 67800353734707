import React, { useState, useEffect, useCallback } from 'react'
import { Row, Col, Button, Table, Input, notification, Modal } from 'antd'
import moment from 'moment'
import MainLayout from '../components/mainlayout'
import StatusTag from '../components/base/status'
import { Helmet } from 'react-helmet'
import { navigate } from 'gatsby'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faChevronRight } from '@fortawesome/free-solid-svg-icons'
import client from '../api/client'
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import 'bootstrap/scss/bootstrap.scss'
const { Search } = Input
const pageHeaderStyle = {
  fontSize: 24,
  fontFamily: 'futura-pt, sans-serif',
  fontWeight: 600,
  color: '#333',
}
const menus = [
  {
    name: 'Идэвхтэй',
    value: 'active',
    status: ['New', 'PartiallyReceived', 'Received', 'ReadyForSend', 'Shipped', 'ReadyForDelivery'],
  },
  {
    name: 'Багц илгээмжүүд',
    value: 'bulkList',
    status: ['Bulk'],
  },
  {
    name: 'Архив',
    value: 'archive',
    status: ['Delivered'],
  },
]
const formatPhone = (number) => {
  if (number) {
    const cleaned = number.replace(/\D/g, '').substring(0, 10)

    const zip = cleaned.substring(0, 3)
    const middle = cleaned.substring(3, 6)
    const last = cleaned.substring(6, 10)

    let ret = ''
    if (cleaned.length > 6) ret = `(${zip}) ${middle}-${last}`
    else if (cleaned.length > 3) ret = `(${zip}) ${middle}`
    else if (cleaned.length > 0) ret = `(${zip}`
    return ret
  }
  return ''
}
//
// Swap array
//
var swapArrayElements = function (arr, indexA, indexB) {
  var temp = arr[indexA]
  arr[indexA] = arr[indexB]
  arr[indexB] = temp
}
const Home = () => {
  const [activeMenu, setActiveMenu] = useState('active')
  const [branchList, setBranchList] = useState([])
  const [loading, setLoading] = useState(true)
  const [ordersInfo, setOrdersInfo] = useState({})
  const [bulkOrdersInfo, setBulkOrdersInfo] = useState({})
  const [paginatedRequestData, setPaginatedRequestData] = useState({
    search: '',
    pagination: {
      current: 1,
      pageSize: 10,
    },
    sorter: {
      field: 'createdDate',
      order: 'descend',
    },
    status: ['New', 'PartiallyReceived', 'Received', 'ReadyForSend', 'Shipped', 'ReadyForDelivery'],
  })
  const [paginatedBulkData, setPaginatedBulkData] = useState({
    search: '',
    pagination: {
      current: 1,
      pageSize: 10,
    },
    sorter: {
      field: 'createdDate',
      order: 'descend',
    },
  })

  useEffect(() => {
    // TODO : NEED BE - SortId
    client.get('/api/lookup/branches').then(async (res) => {
      const branchList = res.data.map((el, idx) => {
        return {
          id: el.id,
          name: el.name,
          phone: el.phone,
          address: el.address,
          isReceiveOnline: el.isReceiveOnline,
          sort: idx,
        }
      })
      // FIX: next line code was crashing the website array will be sorted in backend
      // swapArrayElements(branchList, 3, 6)
      setBranchList(branchList)
    })
  }, [])

  const fetchBulkOrders = useCallback(() => {
    client.post('/api/orders/bulkorders/paginated', { ...paginatedBulkData }).then(async (res) => {
      setBulkOrdersInfo({
        tableData: res.data.data,
        dataCount: res.data.total,
      })
      // If the last element of page is deleted it should go previous page
      if (
        res.data.total >= 0 &&
        res.data.total + paginatedBulkData.pagination.pageSize === paginatedBulkData.pagination.pageSize * paginatedBulkData.pagination.current
      )
        setPaginatedBulkData({
          ...paginatedBulkData,
          pagination: {
            pageSize: paginatedBulkData.pagination.pageSize,
            current: paginatedBulkData.pagination.current - 1,
          },
        })
      setLoading(false)
    })
  }, [paginatedBulkData])

  useEffect(() => {
    fetchBulkOrders()
  }, [paginatedBulkData, fetchBulkOrders])

  useEffect(() => {
    client.post('/api/orders/paginated', { ...paginatedRequestData }).then(async (res) => {
      setOrdersInfo({
        tableData: res.data.data,
        dataCount: res.data.total,
      })
      setLoading(false)
    })
  }, [paginatedRequestData])

  const createBulkOrder = () => {
    client
      .post('/api/orders/bulkorders')
      .then(async (res) => {
        navigate(`/bulk/${res.data.id}`)
      })
      .catch((error) => {
        console.error(error.message)
        notification.error({ message: 'Алдаа гарлаа' })
      })
    setLoading(false)
  }

  const columns = [
    {
      title: 'Код',
      className: 'text-center',
      dataIndex: 'code',
    },
    {
      title: 'Жин',
      className: 'text-center',
      dataIndex: 'weight',
      responsive: ['sm'],
    },
    {
      title: 'Хүлээн авсан огноо',
      className: 'text-center',
      dataIndex: 'createdDate',
      render: (record) => {
        return moment(record).format('MM/DD/YYYY')
      },
    },
    {
      title: 'Хүлээн авагч',
      className: 'text-center',
      responsive: ['xl'],
      render: (record) => `${record.receiver.firstName} ${record.receiver.lastName}`,
    },
    {
      title: 'Төлөв',
      className: 'text-center',
      responsive: ['sm'],
      render: (record) => <StatusTag status={record.customerStatus} />,
    },
    {
      title: 'Мэссэж болон и-мейл хүлээн авч буй эсэх',
      className: 'text-center',
      width: 115,
      render: (record) => (record.willReceiveMessage ? 'Тийм' : 'Үгүй'),
    },
    {
      title: 'Үйлдэл',
      className: 'text-center',
      dataIndex: 'id',
      render: (record) => {
        return (
          <Button
            className="btn-sb btn-sb-primary px-3"
            onClick={() => {
              navigate(`/order/${record}`)
            }}
          >
            <FontAwesomeIcon icon={faEye} size="xs" />
          </Button>
        )
      },
    },
  ]
  const bulkColumns = [
    {
      title: 'Тракын тоо',
      className: 'text-center',
      dataIndex: 'trackCount',
    },
    {
      title: 'Илгээмжийн тоо',
      className: 'text-center',
      dataIndex: 'orderCount',
      responsive: ['sm'],
    },
    {
      title: 'Тайлбар',
      className: 'text-center',
      dataIndex: 'instruction',
      responsive: ['md'],
    },
    {
      title: 'Хүлээн авсан огноо',
      className: 'text-center',
      dataIndex: 'createdDate',
      render: (record) => {
        return moment(record).format('MM/DD/YYYY')
      },
    },
    {
      title: 'Үйлдэл',
      className: 'text-center',
      render: (record) => {
        return (
          <>
            <Button
              className="btn-sb btn-sb-primary px-3 me-2"
              onClick={() => {
                navigate(`/bulk/${record.id}`)
              }}
            >
              <FontAwesomeIcon icon={faEye} size="xs" />
            </Button>
            <Button
              className="btn-sb btn-sb-danger px-3"
              onClick={() => {
                if (!record.orderCount)
                  Modal.confirm({
                    title: 'Та энэ багц илгээмжийг устгахдаа итгэлтэй байна уу?',
                    icon: <ExclamationCircleOutlined />,
                    className: 'sb-modal',
                    okText: 'Устгах',
                    okType: 'danger',
                    cancelText: 'Цуцлах',
                    centered: true,
                    onOk: () => {
                      client
                        .delete(`/api/orders/bulkorders/${record.id}`)
                        .then(async (res) => {
                          fetchBulkOrders()
                          notification.success({ message: 'Багц илгээмж амжилттай устгагдлаа!' })
                        })
                        .catch((err) => console.error({ err }))
                    },
                  })
                else notification.error({ message: 'Илгээмжтэй багцийг устгах боломжгүй!' })
              }}
            >
              <FontAwesomeIcon icon={faTrashAlt} size="1x" />
            </Button>
          </>
        )
      },
    },
  ]

  return (
    <MainLayout>
      <Helmet title="Нүүр хуудас - SB Express" defer={false} />
      <Row>
        <Col span={24} style={pageHeaderStyle}>
          <span>Илгээмжүүд</span>
        </Col>
      </Row>
      <Row>
        <Col lg={18} xs={24} className="pe-lg-4">
          <Row>
            <Col flex="auto">
              {menus.map((menu, index) => {
                if (menu.value === 'bulkList')
                  return (
                    <Button
                      key={index}
                      className="btn-sb btn-sb-header"
                      onClick={async () => {
                        setLoading(true)
                        await setActiveMenu(menu.value)
                        fetchBulkOrders()
                      }}
                      style={{
                        color: activeMenu === menu.value ? '#11427F' : '#333333',
                      }}
                    >
                      {menu.name}
                    </Button>
                  )
                else
                  return (
                    <Button
                      key={index}
                      className="btn-sb btn-sb-header"
                      onClick={async () => {
                        setLoading(true)
                        await setActiveMenu(menu.value)
                        setPaginatedRequestData({
                          ...paginatedRequestData,
                          status: menu.status,
                        })
                      }}
                      style={{
                        color: activeMenu === menu.value ? '#11427F' : '#333333',
                      }}
                    >
                      {menu.name}
                    </Button>
                  )
              })}
            </Col>
            <Col flex="255px" style={{ marginLeft: '40px' }}>
              {activeMenu === 'bulkList' ? (
                ''
              ) : (
                <Search
                  className="w-100 sb-search sb-shadow"
                  placeholder="Код, хүлээн авагч"
                  onSearch={(value) => {
                    setLoading(true)
                    setPaginatedRequestData({
                      ...paginatedRequestData,
                      pagination: {
                        ...paginatedRequestData.pagination,
                        current: 1,
                      },
                      search: value,
                    })
                  }}
                />
              )}
            </Col>
            <Col span={24}>
              {activeMenu === 'bulkList' ? (
                <Table
                  className="sb-table mt-3"
                  dataSource={bulkOrdersInfo.tableData}
                  columns={bulkColumns}
                  loading={loading}
                  rowKey={(record) => record.id}
                  locale={{ emptyText: 'Мэдээлэл байхгүй байна' }}
                  onChange={(pagination, filter, sorter) => {
                    setLoading(true)
                    setPaginatedBulkData({
                      ...paginatedBulkData,
                      pagination: {
                        current: pagination.current,
                        pageSize: pagination.pageSize,
                      },
                    })
                  }}
                  pagination={{
                    total: bulkOrdersInfo.dataCount,
                    current: paginatedBulkData.pagination.current,
                    pageSize: paginatedBulkData.pagination.pageSize,
                  }}
                />
              ) : (
                <Table
                  className="sb-table mt-3"
                  dataSource={ordersInfo.tableData}
                  columns={columns}
                  loading={loading}
                  rowKey={(record) => record.id}
                  locale={{ emptyText: 'Мэдээлэл байхгүй байна' }}
                  onChange={(pagination, filter, sorter) => {
                    setLoading(true)
                    setPaginatedRequestData({
                      ...paginatedRequestData,
                      pagination: {
                        current: pagination.current,
                        pageSize: pagination.pageSize,
                      },
                    })
                  }}
                  pagination={{
                    total: ordersInfo.dataCount,
                    current: paginatedRequestData.pagination.current,
                    pageSize: paginatedRequestData.pagination.pageSize,
                  }}
                />
              )}
            </Col>
          </Row>
        </Col>
        <Col lg={6} xs={24}>
          <Row gutter={24}>
            <Col lg={24} sm={12} xs={24}>
              <Button
                className="btn-sb btn-sb-primary w-100 mb-2"
                htmlType="submit"
                onClick={() => {
                  navigate('/order/new')
                }}
              >
                Илгээмж бүртгэх
                <span className="btn-sb-icon">
                  <FontAwesomeIcon icon={faChevronRight} size="xs" />
                </span>
              </Button>
              <Button
                className="btn-sb btn-sb-primary w-100 mb-2"
                htmlType="submit"
                onClick={() => {
                  createBulkOrder()
                }}
              >
                Багц илгээмж үүсгэх
                <span className="btn-sb-icon">
                  <FontAwesomeIcon icon={faChevronRight} size="xs" />
                </span>
              </Button>
            </Col>
            <Col lg={24} sm={12} xs={24} className="mt-3">
              <div className="bg-grey-medium sb-shadow-3 sb-address">
                <span className="sb-address-title">Салбарууд</span>
                <hr />
                {branchList.length > 0 &&
                  branchList.map((el, index) => {
                    let phone = ''
                    if (!(el?.phone === '' || el?.phone === null)) {
                      phone = el?.phone.replace(/\D/g, '')
                    }
                    return (
                      <div key={index}>
                        {el?.address === null || el?.address === '' ? (
                          ''
                        ) : (
                          <div className="mt-3 mb-3" style={{ fontWeight: 400, fontSize: 16 }}>
                            <div>
                              <b>{el?.name}</b>
                            </div>
                            <div>{el?.address}</div>
                            <div>{formatPhone(phone)}</div>
                            {el?.isReceiveOnline ? (
                              <div className="text-green" style={{ fontWeight: 500 }}>
                                Online захиалгын ачаа авч байна
                              </div>
                            ) : (
                              <div className="text-red" style={{ fontWeight: 500 }}>
                                Online захиалгын ачаа авахгүй
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                    )
                  })}
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </MainLayout>
  )
}
export default Home
